// https://www.npmjs.com/package/pristinejs
import Pristine from 'pristinejs';

// https://www.npmjs.com/package/dom-form-serializer
import {serialize} from 'dom-form-serializer';

const newsletterForm = document.querySelector('#newsletterForm');

let validate = null;

if (newsletterForm && newsletterForm.length) {
    newsletterForm.addEventListener('submit', formSubmitHandler, { passive: false });
    
    validate = new Pristine(newsletterForm);
}


// polyfill for fetch which is not supported on legacy browsers susch as ie11
// https://www.npmjs.com/package/isomorphic-fetch
require('es6-promise').polyfill();
require('isomorphic-fetch');

function formSubmitHandler(event) {
    event.preventDefault();

    // validate the form
    if (validate.validate()) {
        const formData = serialize(newsletterForm);
        
        // check if user has inputted data into form
        if (formData) {
            // submit the form
            const submitForm = formDataSubmit(formData);

            submitForm.then(async (data) => {
                
                if (data.response == "Thank Your for Subscribing") {
                    newsletterFormWrapper.innerHTML = "<p class='colour-black'>" + data.response + "</p>";
                }
            });
        }

    }
}

function formDataSubmit(formData) {
	return fetch('/newsletters/', 
    {
        method: "POST",
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        credentials: 'same-origin',
        body: JSON.stringify(formData)
    })
    .then((response) => {
        return response.json().then((data) => {
            return data;
        });
    });
}