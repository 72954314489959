import './sass/app.scss';
// import 'font-awesome/css/font-awesome.css';

import 'bootstrap.native';

import 'lazysizes';

import './js/newsletter.js';
import './js/contact_form.js'; 
// import './js/booking_session_timer';

// add activity / package to enquiry modal
const modalWrapper = document.querySelector('#contactModal');
const modalButton = document.querySelectorAll('[data-target="#contactModal"]');

// check to see if page has modalButtons
if (modalButton) {
    // loop through the modaButtons and bind a click event listener
    for (const button of modalButton) {
        button.addEventListener('click', modalButtonHandler, { passive: true });
    }
}

function modalButtonHandler(event) {
    // grab the activity data attribute and add it to the preferredPackage input
    modalWrapper.querySelector('#enquiries_preferredPackage').value = event.target.dataset.activity ? event.target.dataset.activity : '';
}