// https://www.npmjs.com/package/pristinejs
import Pristine from 'pristinejs';

// https://flatpickr.js.org/
import flatpickr from "flatpickr";
require("flatpickr/dist/themes/light.css");

const dateOptions = {
    altInput: true,
    altFormat: "j F, Y",
    dateFormat: "d/m/Y",
    minDate: "today"
}

const contactForm = document.querySelector('.contact-form');
const datePickerWrapper = document.querySelector('#enquiries_travelDate');

// check to see if datePickerWrapper is present on the page
if (datePickerWrapper) {
  flatpickr('#enquiries_travelDate', dateOptions);
}

let validate = null;

// check if contact form is present
if (contactForm) {
  validate = new Pristine(contactForm);
  contactForm.addEventListener('submit', contactFormHandler), { passive: false };
}

function contactFormHandler(event) {
  event.preventDefault();
  // validate the form
  if (validate.validate()) {
    event.target.submit();
  }
}